<ng-container *ngIf="mode === 1 && isVisible">
  <div class="z-[60] fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-300 opacity-100">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="absolute right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2">
        <div class="mb-6 font-bold text-center text-lg text-white">
          {{ text }}
        </div>
        <div style="border-top-color: transparent" class="m-auto border-solid animate-spin rounded-full border-primary-400 border-8 h-32 w-32"></div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode === 2">
  <div class="w-full overflow-hidden">
    <div class="w-1/2 inline-block relative fluentProgressBar-waiting bg-black"></div>
  </div>
  <div class="h2 text-lg mt-2 block text-center" *ngIf="text">
    {{ text }}
  </div>
</ng-container>

<ng-container *ngIf="mode === 3">
  <div style="border-top-color: transparent" class="m-auto border-solid animate-spin rounded-full border-primary-400 border-8 h-32 w-32"></div>
  @if(text){
  <div class="flex justify-center mt-2 text-center">{{ text }}</div>
  }
</ng-container>
